import _export from "../internals/export";
import _isIntegralNumber from "../internals/is-integral-number";
var $ = _export;
var isIntegralNumber = _isIntegralNumber; // `Number.isInteger` method
// https://tc39.es/ecma262/#sec-number.isinteger

$({
  target: "Number",
  stat: true
}, {
  isInteger: isIntegralNumber
});
export default {};